@mixin font-face($font-name, $font-name-weight, $weight: 400, $style: normal)
  @font-face
    font-family: $font-name
    src: url("../fonts/#{$font-name}/#{$font-name}-#{$font-name-weight}.woff2") format('woff2'), url("../fonts/#{$font-name}/#{$font-name}-#{$font-name-weight}.woff") format('woff')
    font-weight: $weight
    font-style: $style


$font-name: "Lato"

@include font-face($font-name, Light, 200)
@include font-face($font-name, Regular)
@include font-face($font-name, Medium, 500)
@include font-face($font-name, SemiBold, 600)
@include font-face($font-name, Bold, 700)
@include font-face($font-name, Exstra-Bold, 800)


html
  font-family: $font-name, sans-serif
  font-weight: 400
  -webkit-text-size-adjust: none
  line-height: 1.3
  font-size: 16px

h1, h2, h3, h4, h5, h6, p
  font-weight: 400
  & > strong
    font-weight: 400