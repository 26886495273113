@import "containers"

.hidden
  display: none !important

section
  scroll-margin: 130px
  @include mobile
    scroll-margin: 80px


.title-section
  font-size: 36px
  font-weight: 500
  margin-bottom: 32px
  color: $text-dark
  @include fontSize(24, 36)
  @include mobile
    font-weight: 800
    text-align: center

.flex-center
  display: flex
  align-items: center
  justify-content: center


.button
  position: relative
  display: flex
  align-items: center
  justify-content: center
  font-weight: 700
  padding: 8px 32px
  min-height: 44px
  min-width: 150px
  transition: $transition
  line-height: 1
  width: fit-content
  font-size: 18px
  user-select: none
  overflow: hidden
  white-space: nowrap
  &.button--outline
    border: 2px solid $text-dark
    &:hover
      box-shadow: $primary 4px 4px 0 0
  &.button--primary
    background-color: $primary
    &:hover
      box-shadow: rgba(0, 0, 0, 1) 4px 4px 0 0
  &.button--secondary
    background-color: $secondary
    &:hover
      box-shadow: rgba(0, 0, 0, 1) 4px 4px 0 0



.desktop-hidden
  @media (min-width: $width-mobile)
    display: none

.mobile-hidden
  @media (max-width: $width-mobile)
    display: none

.tablet-hidden
  @media (min-width: $width-tablet)
    display: none
