.institution-inner
  margin-top: 50px
  font-size: 17px
  line-height: 1.4
  @include mobile
    font-size: 15px


  &__container
    display: flex
    flex-direction: column
    gap: 32px
    @include mobile
      gap: 24px

  &__title
    @include fontSize(25, 36)
    display: flex
    gap: 32px
    align-items: center
    @include mobile
      flex-direction: column
      gap: 24px
      align-items: flex-start
      font-weight: 800

  &__desc
    display: flex
    flex-direction: column
    gap: 32px
    @include mobile
      gap: 24px

  &__item
    h2
      font-weight: 600
      @include fontSize(18, 25)
    ul
      margin-top: 32px
      padding-left: 24px
      display: flex
      flex-direction: column
      gap: 4px
      @include mobile
        margin-top: 24px
      li
        list-style-type: disc


  &__button
    max-width: 300px
    width: 100%
    margin-top: 24px

