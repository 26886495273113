.header
  position: sticky
  top: 0
  z-index: 2
  width: 100%
  min-height: 68px
  display: flex
  align-items: center
  padding: 12px 0
  transition: 0.5s
  font-weight: 600
  background-color: $white
  box-shadow: 0 0 16px 0 rgba(#000, 0.08)


  &__container
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    gap: 32px
    z-index: 3

  &__logo
    position: relative
    z-index: 1
    transition: $transition
    flex: 0 0 100px

  &-nav
    @include mobile
      display: flex
      flex-direction: column
      top: 0
      left: 0
      position: fixed
      width: 100%
      height: 100vh
      background-color: $bg
      transform: translateY(-100%)
      opacity: 0
      visibility: hidden
      transition: $transition
      padding: 88px 24px 126px
      &.js-mobile-nav-open
        transform: translateY(0)
        opacity: 1
        visibility: visible
      &.js-mobile-nav-open ~ .header__burger .header__burger-line-1
        transform: rotate(45deg)
      &.js-mobile-nav-open ~ .header__burger .header__burger-line-2
        transform: scale(0)
      &.js-mobile-nav-open ~ .header__burger .header__burger-line-3
        transform: rotate(-45deg)

      .header__login
        margin: auto auto 0
        width: 100%

    &__list
      display: flex
      align-items: center
      gap: 56px
      @include tablet
        gap: 32px
      @include mobile
        flex-direction: column
        align-items: flex-end

    &__link
      font-size: 18px
      white-space: nowrap
      transition: $transition


  &__burger
    display: none
    @include mobile
      position: relative
      z-index: 100
      display: flex
      flex-direction: column
      justify-content: space-between
      width: 20px
      height: 16px
      flex: 0 0 20px
      &-line
        display: block
        background-color: #000
        width: 100%
        height: 2px
        border-radius: 10px
      &-line-1
        transform-origin: 0 0
        transition: transform 0.4s ease-in-out
      &-line-2
        transition: transform 0.2s ease-in-out
      &-line-3
        transform-origin: 0 100%
        transition: transform 0.4s ease-in-out