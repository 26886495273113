.with-us

  &__content
    max-width: 700px
    margin-left: auto

  &__list
    display: grid
    grid-template-columns: auto auto
    grid-template-rows: repeat(4, auto)
    grid-column-gap: 48px
    grid-row-gap: 18px
    grid-auto-flow: column
    @include mobile
      display: flex
      flex-direction: column

  &__item
    position: relative
    font-size: 17px
    display: flex
    align-items: center
    gap: 12px
    &::before
      content: ""
      display: inline-block
      background-image: url("../img/icons/with-us-check.svg")
      width: 28px
      height: 28px
      background-size: cover
      background-position: center
      background-repeat: no-repeat

    &.with-us__item--hidden
      opacity: 0
      visibility: hidden
      @include mobile
        display: none