$transition: all 0.24s ease-in-out

$width-tablet: 1200px
$width-mobile: 767px

$white: #fff
$bg: #fff
$text: #494949
$text-dark: #282727
$primary: #7DBBFF
$secondary: #E498A0
$yellow: #E9F4A6
