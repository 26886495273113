.helps
  position: relative
  @include mobile
    &::before
      content: ""
      position: absolute
      left: 0
      top: 45%
      background-image: url("../img/decor/helps-mob.svg")
      width: 60px
      height: 320px
      background-size: cover
      background-position: center
      background-repeat: no-repeat

  &__items
    position: relative
    display: grid
    grid-template-columns: repeat(4, 1fr)
    gap: 40px
    @include tablet
      grid-template-columns: repeat(2, 1fr)
    @include mobile
      grid-template-columns: 1fr
      justify-items: right
      margin-right: 36px
    @media (min-width: $width-mobile)
      &::after
        content: ""
        position: absolute
        right: 0
        top: 280px
        background-image: url("../img/decor/helps.svg")
        width: 325px
        height: 446px
        background-size: cover
        background-position: center
        background-repeat: no-repeat
        @media (max-width: 1440px)
          transform: scale(0.7)
        @include tablet
          top: 470px
          transform: scale(0.65)
        @media (max-width: 1050px)
          top: 420px


  &-item
    display: flex
    flex-direction: column
    align-items: flex-end
    gap: 26px
    max-width: 280px

  &-item__num
    position: absolute
    left: 0
    top: 50%
    transform: translate(-60%, -50%)
    line-height: 1
    font-size: 130px
    font-weight: 800
    color: $text-dark


  &-item__img
    position: relative
    img
      width: 160px
      box-shadow: rgba(0, 0, 0, 1) 10px 10px 0 0

  &-item__text
    font-size: 18px
    text-align: right
    text-wrap: balance
    @include mobile
      max-width: 250px


  &__button
    width: 300px
    margin: 100px auto 0
    @include mobile
      width: 100%

