.loader__box
  width: 100%
  height: 100%
  background-color: black
  position: fixed
  top: 0
  z-index: 1000
  transition: 0.5s
  display: flex
  align-items: center
  justify-content: center
  &.hide
    opacity: 0
    visibility: hidden


.loader
  width: 10px
  height: 10px
  border-radius: 50%
  display: block
  margin: 15px auto
  position: relative
  color: #FFF
  left: -100px
  box-sizing: border-box
  animation: shadowRolling 2s linear infinite
@keyframes shadowRolling
    0%
        box-shadow: 0px 0 rgba($primary, 0), 0px 0 rgba($primary, 0), 0px 0 rgba($primary, 0), 0px 0 rgba($primary, 0)
    12%
      box-shadow: 100px 0 white, 0px 0 rgba($primary, 0), 0px 0 rgba($primary, 0), 0px 0 rgba($primary, 0)
    25%
      box-shadow: 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0)
    36%
      box-shadow: 120px 0 white, 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0)
    50%
      box-shadow: 130px 0 white, 120px 0 white, 110px 0 white, 100px 0 white
    62%
      box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white, 110px 0 white
    75%
      box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white
    87%
      box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white
    100%
      box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0)
