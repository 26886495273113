.about

  &__text
    display: flex
    flex-direction: column
    gap: 16px
    font-size: 17px
    max-width: 770px
    line-height: 1.5
    @include mobile
      gap: 12px